import { propertiesToCamel } from '../utils'

const parseAccount = data => {
  return {
    id: data.id,
    name: data.attributes.name,
  }
}


export const parseWidgetInstallation = data => {
  return {
    id: data.id,
    ...propertiesToCamel(data.attributes),
    account: data.attributes.account ? parseAccount(data.attributes.account.data) : null,
  }
}

export const prepareFilters = filters => {
  const sanitizedFilters = Object.keys(filters).reduce((acc, key) => {
    const filter = filters[key]
    if (filter) {
      acc[key] = filter
    }
    return acc
  }, {})
  return sanitizedFilters
}

export const parseResponse = response => {
  return {
    data: response.data.map(parseWidgetInstallation),
    meta: {
      totalCount: response.meta.page.total_count,
      ...response.links,
    },
  }
}
