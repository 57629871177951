import { useState, useEffect } from 'react'

import { parseResponse } from './utils'
import { genericGet } from '../utils'
import useLiveChat from '../application/livechat'

const resourceBasePath = '/v1/livechat_leads?'

const useLeads = (livechatId) => {
  const [opportunities, setOpportunities] = useState()
  const [{ groupId, accessToken }] = useLiveChat();
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState()
  const [sort, setSort] = useState({})

  const filters = { livechat_thread_id: livechatId }

  const getOpportunitiesFirstPage = () => {
    setLoading(true)
    return genericGet(resourceBasePath, {
      filter: filters,
      sort: sort.column ? `${sort.order === 'descend' ? '-' : ''}${sort.column.key}` : '',
    }, groupId, accessToken)
      .then(parseResponse)
      .then(response => {
        setOpportunities(response.data)
        setMeta(response.meta)
      })
      .catch(e => {
        console.error(e)
      })
      .then(() => {
        setLoading(false)
      })
  }
  const getNextPage = () => {
    if (loading || !meta.next) {
      return
    }
    setLoading(true)
    genericGet(meta.next, filters, groupId, accessToken)
      .then(parseResponse)
      .then(response => {
        setOpportunities([...opportunities, ...response.data])
        setMeta(response.meta)
      })
      .catch(e => {
        console.error(e)
      })
      .then(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (groupId && accessToken && livechatId) {
      getOpportunitiesFirstPage()
    }
  }, [sort, livechatId, groupId, accessToken])

  return [
    {
      loading,
      leads: opportunities,
      meta,
      filters,
    },
    {
      getNextPage,
      setSort,
    },
  ]
}

export default useLeads
