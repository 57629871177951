import { useState, useEffect } from 'react'

import { parseResponse, prepareFilters } from './utils'
import { genericGet } from './api'
import useLiveChat from '../application/livechat'

const resourceBasePath = '/v1/installations?'

const DEFAULT_FILTER = 'name';

const useWidgetInstallations = () => {
  const [widgetInstallations, setWidgetInstallations] = useState([])
  const [{ groupId, accessToken }] = useLiveChat();
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState();

  const getWidgetInstallationsFirstPage = () => {
    setLoading(true)
    setWidgetInstallations([])
    return genericGet(
      resourceBasePath, {
        filter: prepareFilters({}),
        sort: DEFAULT_FILTER
      },
      groupId,
      accessToken
    )
      .then(parseResponse)
      .then(response => {
        setWidgetInstallations(response.data)
        setMeta(response.meta)
      })
      .catch(e => {
        console.error(e)
      })
      .then(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (accessToken && groupId) {
      getWidgetInstallationsFirstPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, groupId])

  return [
    {
      loading,
      widgetInstallations,
      meta,
    }
  ]
}

export default useWidgetInstallations
