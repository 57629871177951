export const parseGroup = group => {
  return {
    id: +group.id,
    name: group.attributes.name,
    relatedQuestions: group.attributes.entries_count,
  }
}

export const parseGroups = groups => {
  return groups.map(parseGroup)
}

const parseQuestion = question => {
  return {
    id: question.id,
    answer: question.attributes.answer,
    question: question.attributes.question,
    keywords: question.attributes.tags,
    groupId: +(((question.attributes.group || {}).data || {})).id,
    groupName: ((((question.attributes.group || {}).data || {}).attributes || {})).name,
    status: question.attributes.status,
    suggestion: question.attributes.suggestion,
    updatedAt: question.attributes.updated_at
      ? new Date(question.attributes.updated_at * 1000).toISOString()
      : null,
  }
}

export const parseQuestions = questions => {
  return questions.map(parseQuestion)
}

const parseLead = lead => {
  if (!lead) {
    return {};
  }

  return {
    id: lead.id,
    firstName: lead.attributes.first_name,
    lastName: lead.attributes.last_name,
    email: lead.attributes.email,
    phone: lead.attributes.phone,
    comments: lead.attributes.comments,
    status: lead.attributes.status
  };
};

export const parseChat = item => {
  if (!item) {
    return null;
  }

  return {
    id: item.id,
    lead: parseLead((item.attributes.leads || [])[0]) // only take the first element
  }
}
