import { useState, useEffect } from 'react'

import { parseResponse } from './utils'
import { genericGet } from '../utils'
import useLiveChat from '../application/livechat'
import useWidgetInstallations from '../widgetInstallations/hook'

const resourceBasePath = '/v1/installation_actions?'

const useActions = () => {
  const [actions, setActions] = useState([])
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState()
  const [filters, setFilters] = useState()
  const [sort, setSort] = useState()
  const [{ widgetInstallations }]= useWidgetInstallations()
  const [{ groupId, accessToken }] = useLiveChat();

  useEffect(() => {
    if (widgetInstallations && widgetInstallations.length && groupId) {
      const installation = (widgetInstallations.find((wi) => (wi.livechatGroupId + '') === (groupId + '')) || [])

      setFilters({
        installation_ids: installation.id
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetInstallations, groupId])

  const getActionsFirstPage = () => {
    setLoading(true)
    return genericGet(resourceBasePath, {
      filter: filters,
      sort: 'name',
    }, groupId, accessToken)
      .then(parseResponse)
      .then(response => {
        setActions(response.data)
        setMeta(response.meta)
      })
      .catch(e => {
        console.error(e)
      })
      .then(() => {
        setLoading(false)
      })
  }

  const getNextPage = () => {
    if (loading || !meta.next) {
      return
    }
    setLoading(true)
    genericGet(meta.next, filters, groupId, accessToken)
      .then(parseResponse)
      .then(response => {
        setActions([...actions, ...response.actions])
        setMeta(response.meta)
      })
      .catch(e => {
        console.error(e)
      })
      .then(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (accessToken && filters && filters.installation_ids) {
      getActionsFirstPage()
    }
  }, [sort, JSON.stringify(filters), accessToken, groupId])

  return [
    {
      loading,
      actions,
      meta,
    },
    {
      getActionsFirstPage,
      getNextPage,
      setFilters,
      setSort,
    },
  ]
}

export default useActions
