import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Input, Button, Form, notification, Collapse } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Select as AntdSelect } from 'antd';
import { useIntl } from 'react-intl';

import useApplication from '../../store/application/hook';
import useActions from '../../store/actions/hook';
import Select from '../Select';
import useLeads from '../../store/leads/hook';

const Wrapper = styled.div`
  padding: 16px 14px 20px 14px;
  text-align: center;
  textarea {
    height: 73px;
  }
  button {
    width: 146px;
    height: 33px;
    border-radius: 3px;
    background-color: #f5a623;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    border: none;
  }
`;

const StyledCollapse = styled(Collapse)`
  i[class*="anticon-right"]  {
    color: #37b34a !important;
  }
`;

const ClientInformation = () => {
  const [{ isChatEditable, livechatId }, { submitClientInformation, setIsClientInformationPanelOpen }]= useApplication();
  const [{ actions }] = useActions()
  const [{ leads }, { saveLead }] = useLeads(livechatId)
  const [loading, setLoading] = useState(false);
  const [leadId, setLeadId] = useState();
  const [name, setName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [actionToTake, setActionToTake] = useState();
  const [actionId, setActionId] = useState();
  const intl = useIntl();

  const clearForm = () => {
    setLeadId(null)
    setName(null);
    setLastName(null);
    setEmail(null);
    setPhone(null);
    setActionToTake(null);
  };

  useEffect(() => {
    if (actions.length) {
      setActionId(actions[0].id)
    }
    // Clean the selection when there are not results
    if (!actions.length) {
      setActionId();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions])

  useEffect(() => {
    if (leads && leads.length) {
      // populating the first result
      const [lead] = leads 
      setLeadId(lead.id)
      setName(lead.name);
      setLastName(lead.lastName);
      setEmail(lead.email);
      setPhone(lead.phone);
      setActionToTake(lead.comments);
      notification.info({
        message: intl.formatMessage({id : 'leadExist' }),
        description: intl.formatMessage({id : 'leadExistDesc' })
      });
    } else {
      clearForm()
    }
  }, [leads])

  const onSave = () => {
    const dataToStore = {
      name,
      lastName,
      email,
      phone,
      actionToTake,
      actionId,
      id: leadId
    };
    // remove empty values
    Object.keys(dataToStore).forEach((key) => !dataToStore[key] && delete dataToStore[key]);

    setLoading(true)
    submitClientInformation(dataToStore).then((resp) => {
      // store the id to perform an update the second time that the user clicks save
      setLeadId(resp.data.id)
      notification.success({
        message: intl.formatMessage({ id: 'dataSent' }),
        description: intl.formatMessage({ id: 'dataHasBeenSentToOurServers' })
      });
      setLoading(false)
    });
  };
  return (
    <StyledCollapse
      expandIconPosition="right"
      onChange={(panelStatus) => setIsClientInformationPanelOpen(!!panelStatus.length)}
    >
      <Collapse.Panel header={intl.formatMessage({ id: 'addActionToDo'})} style={{ textAlign: 'left', background: 'white', fontWeight: 'bold' }}>
        <Wrapper>
          <Form.Item>
            <Row gutter={[8, 8]}>
              <Col className="gutter-row" span={12}>
                  <Input
                    disabled={!isChatEditable}
                    placeholder={intl.formatMessage({ id: 'nameRequired'})}
                    value={name}
                    onChange={(e) => { setName(e.target.value); }}
                  />
              </Col>
              <Col className="gutter-row" span={12}>
                  <Input
                    disabled={!isChatEditable}
                    placeholder={intl.formatMessage({ id: 'lastName'})}
                    value={lastName}
                    onChange={(e) => { setLastName(e.target.value); }}
                  />
              </Col>
            </Row>
          </Form.Item>
          <Row gutter={[8, 8]}>
            <Col className="gutter-row" span={12}>
                <Input
                  disabled={!isChatEditable}
                  placeholder={intl.formatMessage({ id: 'emailRequired'})}
                  value={email}
                  onChange={(e) => { setEmail(e.target.value); }}
                />
            </Col>
            <Col className="gutter-row" span={12}>
                <Input
                  disabled={!isChatEditable}
                  placeholder={intl.formatMessage({ id: 'phone'})}
                  value={phone}
                  onChange={(e) => { setPhone(e.target.value); }}
                />
            </Col>
          </Row>
          <Row>
            <Col className="gutter-row" span={24}>
                <Form.Item style={{marginBottom: '0', marginTop: '10px'}}>
                  <Select value={actionId} onChange={e => setActionId(e)} disabled={!isChatEditable}>
                    {actions.map(action => (
                      <AntdSelect.Option key={action.id} value={action.id}>
                        {action.name}
                      </AntdSelect.Option>
                    ))}
                  </Select>
                </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="gutter-row" span={24}>
                <Form.Item style={{marginBottom: '0', marginTop: '10px'}}>
                  <TextArea
                    rows={2}
                    style={{resize: 'none', marginBottom: '20px'}}
                    placeholder={intl.formatMessage({ id: 'actionToDo'})}
                    value={actionToTake}
                    disabled={!isChatEditable}
                    onChange={(e) => setActionToTake(e.target.value) }
                  />
                </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="gutter-row" span={24}>
                <Button onClick={onSave} disabled={!(email && isChatEditable) || loading}>{intl.formatMessage({ id: 'save'})}</Button>
            </Col>
          </Row>
        </Wrapper>
      </Collapse.Panel>
    </StyledCollapse>
  );
};

export default ClientInformation;
